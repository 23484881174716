table.style1 {
    border-spacing:1px;
    width:100%;
}
.style1 td:first-child {
    background:#161c24;
    font-family: Arial;
    font-weight:bold;
    font-size: 13px;
    text-transform: capitalize;
    border:none;
}
.style1 tr:first-child td {
    font-size: 13px;
    text-transform: capitalize;
}
.style1 td {
    padding:5px 10px !important;
    font-size: 12px;
    color: white;
}
.style1 tbody tr:nth-child(2) > td:nth-child(2), .style1 tr:nth-child(2) > td:nth-child(3), .style1 tr:nth-child(2) > td:nth-child(3), .style1 tr:nth-child(2) > td:nth-child(4), .style1 tr:nth-child(2) > td:nth-child(5), .style1 tr:nth-child(2) > td:nth-child(6), 
.style1 tr:nth-child(3) > td:nth-child(2), .style1 tr:nth-child(3) > td:nth-child(3), .style1 tr:nth-child(3) > td:nth-child(3), .style1 tr:nth-child(3) > td:nth-child(4), .style1 tr:nth-child(3) > td:nth-child(5), .style1 tr:nth-child(3) > td:nth-child(6),
.style1 tr:nth-child(4) > td:nth-child(2), .style1 tr:nth-child(4) > td:nth-child(3), .style1 tr:nth-child(4) > td:nth-child(3), .style1 tr:nth-child(4) > td:nth-child(4), .style1 tr:nth-child(4) > td:nth-child(5), .style1 tr:nth-child(4) > td:nth-child(6),
.style1 tr:nth-child(5) > td:nth-child(2), .style1 tr:nth-child(5) > td:nth-child(3), .style1 tr:nth-child(5) > td:nth-child(3), .style1 tr:nth-child(5) > td:nth-child(4), .style1 tr:nth-child(5) > td:nth-child(5), .style1 tr:nth-child(5) > td:nth-child(6),
.style1 tr:nth-child(6) > td:nth-child(2), .style1 tr:nth-child(6) > td:nth-child(3), .style1 tr:nth-child(6) > td:nth-child(3), .style1 tr:nth-child(6) > td:nth-child(4), .style1 tr:nth-child(6) > td:nth-child(5), .style1 tr:nth-child(6) > td:nth-child(6),
.style1 tr:nth-child(7) > td:nth-child(2), .style1 tr:nth-child(7) > td:nth-child(3), .style1 tr:nth-child(7) > td:nth-child(3), .style1 tr:nth-child(7) > td:nth-child(4), .style1 tr:nth-child(7) > td:nth-child(5), .style1 tr:nth-child(7) > td:nth-child(6),
.style1 tr:nth-child(8) > td:nth-child(2), .style1 tr:nth-child(8) > td:nth-child(3), .style1 tr:nth-child(8) > td:nth-child(3), .style1 tr:nth-child(8) > td:nth-child(4), .style1 tr:nth-child(8) > td:nth-child(5), .style1 tr:nth-child(8) > td:nth-child(6),
.style1 tr:nth-child(9) > td:nth-child(2), .style1 tr:nth-child(9) > td:nth-child(3), .style1 tr:nth-child(9) > td:nth-child(3), .style1 tr:nth-child(9) > td:nth-child(4), .style1 tr:nth-child(9) > td:nth-child(5), .style1 tr:nth-child(9) > td:nth-child(6),
.style1 tr:nth-child(10) > td:nth-child(2), .style1 tr:nth-child(10) > td:nth-child(3), .style1 tr:nth-child(10) > td:nth-child(3), .style1 tr:nth-child(10) > td:nth-child(4), .style1 tr:nth-child(10) > td:nth-child(5), .style1 tr:nth-child(10) > td:nth-child(6),
.style1 tr:nth-child(11) > td:nth-child(2), .style1 tr:nth-child(11) > td:nth-child(3), .style1 tr:nth-child(11) > td:nth-child(3), .style1 tr:nth-child(11) > td:nth-child(4), .style1 tr:nth-child(11) > td:nth-child(5), .style1 tr:nth-child(11) > td:nth-child(6),
.style1 tr:nth-child(12) > td:nth-child(2), .style1 tr:nth-child(12) > td:nth-child(3), .style1 tr:nth-child(12) > td:nth-child(3), .style1 tr:nth-child(12) > td:nth-child(4), .style1 tr:nth-child(12) > td:nth-child(5), .style1 tr:nth-child(12) > td:nth-child(6),
.style1 tr:nth-child(13) > td:nth-child(2), .style1 tr:nth-child(13) > td:nth-child(3), .style1 tr:nth-child(13) > td:nth-child(3), .style1 tr:nth-child(13) > td:nth-child(4), .style1 tr:nth-child(13) > td:nth-child(5), .style1 tr:nth-child(13) > td:nth-child(6), 
.style1 tr:nth-child(14) > td:nth-child(2), .style1 tr:nth-child(14) > td:nth-child(3), .style1 tr:nth-child(14) > td:nth-child(3), .style1 tr:nth-child(14) > td:nth-child(4), .style1 tr:nth-child(14) > td:nth-child(5), .style1 tr:nth-child(14) > td:nth-child(6),
.style1 tr:nth-child(15) > td:nth-child(2), .style1 tr:nth-child(15) > td:nth-child(3), .style1 tr:nth-child(15) > td:nth-child(3), .style1 tr:nth-child(15) > td:nth-child(4), .style1 tr:nth-child(15) > td:nth-child(5), .style1 tr:nth-child(15) > td:nth-child(6),
.style1 tr:nth-child(16) > td:nth-child(2), .style1 tr:nth-child(16) > td:nth-child(3), .style1 tr:nth-child(16) > td:nth-child(3), .style1 tr:nth-child(16) > td:nth-child(4), .style1 tr:nth-child(16) > td:nth-child(5), .style1 tr:nth-child(16) > td:nth-child(6),
.style1 tr:nth-child(17) > td:nth-child(2), .style1 tr:nth-child(17) > td:nth-child(3), .style1 tr:nth-child(17) > td:nth-child(3), .style1 tr:nth-child(17) > td:nth-child(4), .style1 tr:nth-child(17) > td:nth-child(5),  .style1tr:nth-child(17) > td:nth-child(6),
.style1 tr:nth-child(18) > td:nth-child(2), .style1 tr:nth-child(18) > td:nth-child(3), .style1 tr:nth-child(18) > td:nth-child(3), .style1 tr:nth-child(18) > td:nth-child(4), .style1 tr:nth-child(18) > td:nth-child(5), .style1 tr:nth-child(18) > td:nth-child(6),
.style1 tr:nth-child(19) > td:nth-child(2), .style1 tr:nth-child(19) > td:nth-child(3), .style1 tr:nth-child(19) > td:nth-child(3), .style1 tr:nth-child(19) > td:nth-child(4), .style1 tr:nth-child(19) > td:nth-child(5), .style1 tr:nth-child(19) > td:nth-child(6),
.style1 tr:nth-child(20) > td:nth-child(2), .style1 tr:nth-child(20) > td:nth-child(3), .style1 tr:nth-child(20) > td:nth-child(3), .style1 tr:nth-child(20) > td:nth-child(4), .style1 tr:nth-child(20) > td:nth-child(5), .style1 tr:nth-child(20) > td:nth-child(6),
.style1 tr:nth-child(21) > td:nth-child(2), .style1 tr:nth-child(21) > td:nth-child(3), .style1 tr:nth-child(21) > td:nth-child(3), .style1 tr:nth-child(21) > td:nth-child(4), .style1 tr:nth-child(21) > td:nth-child(5), .style1 tr:nth-child(21) > td:nth-child(6),
.style1 tr:nth-child(22) > td:nth-child(2), .style1 tr:nth-child(22) > td:nth-child(3), .style1 tr:nth-child(22) > td:nth-child(3), .style1 tr:nth-child(22) > td:nth-child(4), .style1 tr:nth-child(22) > td:nth-child(5), .style1 tr:nth-child(22) > td:nth-child(6)
 {
  background: #004b77;
  border:none;
}
table.style1 > tbody > tr:nth-child(1) > td:nth-child(1), .style1 tr:nth-child(1) > td:nth-child(2), .style1 tr:nth-child(1) > td:nth-child(3), .style1 tr:nth-child(1) > td:nth-child(4), .style1 tr:nth-child(1) > td:nth-child(5), .style1 tr:nth-child(1) > td:nth-child(6)  {
    background:#161c24;
    font-family: Arial;
    font-weight:bold;
    font-size: 13px;
    color: white;    
    border:none;
}
.style1 ul {
    margin-left: 20px;
}
table {
    border-spacing:0px;
    
    width:100%;
    
}

.style2 td {
    color:white;
    border:none;
}
.style2 table  {
    border-spacing: 1px;
}
.style3 td {
    border-bottom: solid 1px ;
    border-left:none;
    border-right:none;
    border-top: none;
}
.style3 tr:nth-child(1) > td:nth-child(1), .style3 tr:nth-child(1) > td:nth-child(2), .style3 tr:last-child > td:nth-child(1), .style3 tr:last-child > td:nth-child(2)  {
    border-bottom: none;
}
