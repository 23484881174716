.carousel__slide-focus-ring{
    display:none !important;
}

.carousel__slide:focus{
    outline:none !important;
}
/* .carousel{
    overflow: hidden;
} */
/* #root > div.MuiBox-root.css-1x68dh4 > div.MuiContainer-root.MuiContainer-maxWidthSm.css-1uznc7b-MuiContainer-root > div > div > div > div{
    overflow:unset
} */